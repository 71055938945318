import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
const AccBoxSx = {
  boxShadow: "none",
  border: "1px solid #E3E3E8",
  borderRadius: "8px",
  "&::before": {
    backgroundColor: "unset",
  },
};
const AccordionArr = [
  {
    heading: "Who is this program for? Who can enroll?",
    body: "The program is suitable for these 4:\n  High School Students,College Students (1st/2nd/3rd/4th year), Graduates with Gap Years (2012-2023), Working Professionals (1-10 years of exp. from any domain) \n There are no prerequisites on your degree/background and people from all backgrounds [B.tech / BA / MA / MCA / CA / BCA / B.Com / BBA / MBA / Commerce / Pharma / M.Tech etc.] are welcome to apply \n.",
  },
  {
    heading: "Are there any hidden charges",
    body: "No, there aren’t any hidden charges such as an Income sharing agreement. You just need to pay the enrollment fee for the course. Also, if you successfully complete the course and projects, and get your projects approved, within the given deadline then you will get 100% refund of the enrollment fee.",
  },
  {
    heading: "How will the doubts be resolved if I learn at my own pace?",
    body: "If you have any doubts or difficulties in learning, you can post your questions on the ‘Doubt forum’ on your dashboard’, and they will be resolved quickly. You’ll get exposure to those right after enrolling in the Data Analytics program.",
  },
  {
    heading: "Why should I join Trainity’s Data Analytics Program?",
    body: "There are several reasons why you should consider joining our program:\
    Intense Training: Our program provides 8 weeks of intense training where you will be exposed to real-life industry projects. This will give you hands-on experience in the field of data analytics and prepare you for a career in this industry.\nSelf-Learning: Our modern-day self-learning techniques will help you unlock your self-learning potential, which is essential in the fast-paced field of data analytics.\nMock-Interviews: We conduct mock-interviews for our students so that they are well aware and prepared for the interview that are asked by recruiters during the hiring cycle\nPlacement Assistance: We provide placement assistance once you have completed the projects and got them approved. Our Bootcamp will train you and make you job ready. We also take mock interviews to judge the skills that you have acquired during the training weeks.\nCareer Coaching: Our program offers the best career coaching, where mentors will help you build your resume, LinkedIn profile, Value Document, and teach you how to contact HRs. This will help you stand out from the competition and land the job you want.\nFull Refund: We believe in the quality of our program and offer a full refund if you complete the program efficiently and with dedication.\n    In summary, our Data Analytics Program offers intense training, self-learning techniques, placement assistance, career coaching, and a full refund. Joining our program will prepare you for a successful career in data analytics. We look forward to welcoming you to our program.  ",
  },
  {
    heading: "When will my Placement Assistance start?",
    body: "Once you complete all your 9 projects and get those approved, your Career Tasks will be unlocked. Career Tasks are Placement-Training Sessions taken by Rhea Punjabi, who has 10+ years of experience in career coaching, and corporate training and shaped over 1200+ careers. In these sessions, she will give you a foolproof framework and templates to get you job ready.\
    Once you complete all your 8 Career Task Assignments, you’ll get lifetime access to the HR List. HR list contains contact information of more than 20k+ Hiring Managers of the top companies of India. With the skills and learnings that you have gained till now, throughout your journey with Trainity, and the templates provided by us, you can apply for internships/jobs using these valuable resources. ",
  },
];
function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        padding: { xs: "12px", md: "32px" },
        backgroundColor: "#FFF",
        display: "grid",
        justifyItems: "center",
        maxWidth: "min(100%, 1536px)",
        marginLeft: "auto",
        marginRight: "auto",
        mb: { xs: 6, md: 2 },
      }}
    >
      <Box
        sx={{
          display: "grid",
          rowGap: { md: "32px", xs: "12px" },
          // maxWidth: "100%",
          maxWidth: { md: "848px", xs: "350px" },
        }}
      >
        <Typography
          variant="h1"
          sx={{ textAlign: "center", maxWidth: { md: "848px", xs: "350px" } }}
        >
          Frequently Asked Questions
        </Typography>

        {AccordionArr.map((object, index) => {
          return (
            <Accordion
              expanded={expanded === index + 1 ? true : false}
              onChange={handleChange(index + 1)}
              key={index}
              sx={{
                ...AccBoxSx,
                background: expanded === index + 1 ? "#F9F9FA" : "#FFF",
                maxWidth: { md: "848px", xs: "350px" },
              }}
            >
              <AccordionSummary
                sx={{ border: "none" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h3" sx={{ color: "#717184" }}>
                  {object.heading}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{ maxWidth: { md: "848px", xs: "350px" } }}
                >
                  {object.body}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {/*  */}
      </Box>
    </Box>
  );
}
export default FAQ;
