import React, { useState, forwardRef, useImperativeHandle } from "react";
import "./toast.css";
import { Box, Typography } from "@mui/material";
import { TiTick } from "react-icons/ti";
import { AiOutlineUserAdd, AiOutlineClockCircle } from "react-icons/ai";
import DoneIcon from "@mui/icons-material/Done";
import ClockIcon from "./Icons/ClockIcon";
function Toast({ timeout = 3500, type = 1 }, ref) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ show: false });
  useImperativeHandle(ref, () => ({
    showToast(toastData) {
      setData({ ...toastData, show: true });
      setTimeout(() => {
        setData({ ...data, show: false });
      }, timeout);
    },
  }));
  return (
    <div className={`react-toast-container ${data.show ? "show" : ""} `}>
      {type === 1 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            columnGap: "16px",
            width: "fit-content ",
            padding: "20px",
            alignItems: "center",
            backgroundColor: "#FFF",
            border: "1px solid #DCDCDC",
            borderRadius: "80px",
          }}
        >
          <Box
            sx={{
              width: { md: "80px", xs: "50px" },
              height: { md: "80px", xs: "50px" },
              borderRadius: "100%",
              backgroundColor: "#1B7FF5",
              display: "grid",
              placeContent: "center",
            }}
          >
            <TiTick style={{ width: "50px", height: "50px", color: "#FFF" }} />
          </Box>
          <Box sx={{ display: "grid", rowGap: "3px" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "700",
                width: "fit-content",
                padding: "4px",
              }}
            >
              {data.firstName + " " + data.lastName}
            </Typography>
            <Typography variant="subtitle1">
              Registered for the Data Analytics Program
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                columnGap: "15px",
              }}
            >
              <Typography variant="subtitle2">{data.enrolledAt}</Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "auto 1fr",
                  columnGap: "8px",
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "100%",
                    backgroundColor: "#1B7FF5",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <TiTick
                    style={{ width: "15px", height: "15px", color: "#FFF" }}
                  />
                </Box>{" "}
                verified
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {type === 2 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            columnGap: "16px",
            width: "fit-content ",
            padding: "20px",
            alignItems: "center",
            backgroundColor: "#FFF",
            border: "1px solid #DCDCDC",
            borderRadius: "80px",
          }}
        >
          <Box
            sx={{
              width: { md: "80px", xs: "50px" },
              height: { md: "80px", xs: "50px" },
              borderRadius: "100%",
              backgroundColor: "#1B7FF5",
              display: "grid",
              placeContent: "center",
            }}
          >
            <AiOutlineUserAdd
              style={{ width: "50px", height: "50px", color: "#FFF" }}
            />
          </Box>
          <Box sx={{ display: "grid", rowGap: "3px" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "700",
                width: "fit-content",
                padding: "4px",
              }}
            >
              {data.additionalSeats} Added
            </Typography>
            <Typography variant="subtitle1">
              Additional seats have been added
            </Typography>
          </Box>
        </Box>
      )}
      {type === 3 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            columnGap: "16px",
            width: "fit-content ",
            padding: "20px",
            alignItems: "center",
            backgroundColor: "#FFF",
            border: "1px solid #DCDCDC",
            borderRadius: "80px",
          }}
        >
          <Box
            sx={{
              width: { md: "80px", xs: "50px" },
              height: { md: "80px", xs: "50px" },
              borderRadius: "100%",
              backgroundColor: "#1B7FF5",
              display: "grid",
              placeContent: "center",
            }}
          >
            <AiOutlineClockCircle
              style={{ width: "50px", height: "50px", color: "#FFF" }}
            />
          </Box>
          <Box sx={{ display: "grid", rowGap: "3px" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "16px",
                fontWeight: "700",
                width: "fit-content",
                padding: "4px",
              }}
            >
              Discount Time Extended by {data.extension} min
            </Typography>
            <Typography variant="subtitle1">
              The discount timer has been extended.
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}
export default forwardRef(Toast);
