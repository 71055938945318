import { Button, Modal, Box, Typography } from "@mui/material";
import { useCreateOrder, useMarkPayment } from "../../hooks/paymentPageHooks";
import { useState } from "react";
import { styled } from "@mui/material/styles";

import LoadingAnimation from "../../Pictures/Loading Animation.gif";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

function RazorpayPayButton({ email, phone, name, validateDetails }) {
  const [isMarkingPayment, setIsMarkingPayment] = useState(false);

  const {
    mutate: createOrder,
    isLoading,
    isError,
    error,
    data,
  } = useCreateOrder();

  if (!isLoading && data) {
    let options = {
      key: "rzp_live_ZMdQ4zQVUUc5vR",
      amount: "343800",
      currency: "INR",
      name: "Trainity",
      description: "Data Analytics",
      image: "https://trainity.in/assets/img/trainity/logo/2.svg",
      order_id: data.orderId,
      emailNotify: 1,
      handler: async function (response) {
        const res = await fetch(
          "https://payments.trainity.live/api/payment/mark",
          {
            method: "POST",
            body: JSON.stringify({
              email,
              phone,
              paymentId: response.razorpay_payment_id,
              firstName: name.split(" ")[0],
              lastName: name.split(" ")[1],
            }),
            headers: { "Content-Type": "application/json" },
          }
        );
        const data = await res.json();

        if (data.id) {
          window.location.href =
            "https://enrollment.trainity.in/congratulations/" + data.id;
        }
      },
      prefill: {
        email: email,
        contact: phone,
      },

      theme: {
        color: "#48bf84",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleClick = async () => {
    if (!validateDetails()) return;
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }
    createOrder({ email, phone });
  };
  return (
    <>
      <Modal
        open={isMarkingPayment}
        disableAutoFocus
        sx={{
          "&:focus": {
            outline: "none",
          },
          outline: "none",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            borderRadius: "14px",
            bgcolor: "background.paper",
            display: "grid",
            placeItems: "center",
            p: 4,
          }}
        >
          <Img sx={{ width: "75px", height: "75px" }} src={LoadingAnimation} />
          <Typography
            sx={{ fontSize: "28px", fontWeight: 700, color: "#0f0f0f" }}
            mt={2}
          >
            Hold On...
          </Typography>
        </Box>
      </Modal>
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          color: "#FFF",
          width: "100%",
          padding: "16px 24px",
          backgroundColor: "#389F6C",
          "&:hover": { backgroundColor: "#389F6C" },
          borderRadius: "8px",
        }}
        endIcon={<ArrowForwardIcon />}
        onClick={handleClick}
      >
        Pay ₹3438
      </Button>
    </>
  );
}
export default RazorpayPayButton;
