import { Box, Typography } from "@mui/material";
import google from "../../Pictures/Google.svg";
import slack from "../../Pictures/Slack.svg";
import microsoft from "../../Pictures/Microsoft.svg";
import behance from "../../Pictures/behance.svg";
function Collaboration() {
  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        padding: { xs: "12px", md: "20px" },
        marginBottom: "32px",
        borderRadius: "8px",
        width: "100%",
        mt: 4,
        maxWidth: "min(100%, 1536px)",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography variant="h1">
        {" "}
        Learn From The Top 1% Instructors From Leading Tech Companies
      </Typography>
      <Typography variant="subtitle2" sx={{ fontSize: { md: "1rem" }, mt: 1 }}>
        Dive into data analytics with guidance from the top 1% of instructors
        from leading tech companies, and let us help you succeed on your
        learning journey with us.
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr 1fr", md: "repeat(4,1fr)" },
          rowGap: { xs: "24px", md: "0px" },
          justifyItems: "center",
          alignItems: "center",
          padding: { xs: "0px", md: "0 32px" },
          marginTop: "32px",
        }}
      >
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/1600px-PayPal.svg.png"
          }
          style={{ height: "31px" }}
        />
        <img
          src={"https://cdn.worldvectorlogo.com/logos/flipkart.svg"}
          style={{ height: "31px" }}
        />
        <Box
          component="img"
          src={microsoft}
          sx={{ height: { lg: "31px", xs: "24px" } }}
        />
        <Box
          component="img"
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Dell_Logo.svg/600px-Dell_Logo.svg.png?20160807135325"
          }
          sx={{ height: { lg: "31px", xs: "21px" } }}
        />
      </Box>
    </Box>
  );
}
export default Collaboration;
